<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";




export default {
  props: { 
      oldTabela: { type: Object, required: true },
      listGrupos2: { type: Array, required: true },
     /// parentSelected: { type: Object, required: true }
    },
  components: { 
    //Multiselect 
    },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Alterar Grupo',
      tabelas:{
        ttp_nome: null,
        
      },

     
      
    }
    
    
  },
  validations: {
    tabelas:{
        ttp_nome: {required}, 
      }
  },
  mounted() {
    this.setNewTitle();
    this.tabelas = this.oldTabela;
    this.teste= this.grupo
    
  
    
    
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      console.log('Gravou');
      this.$emit('doPut', this.tabelas);
    },
   
  },
    
}
</script>

<template>

<div class="card">
  <div class="card-body">
    <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
    <form id="form-tipo-movimento" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <h4>Tipo de Tabelas de Preço</h4>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                  <div class="col">
                    <input v-model="tabelas.ttp_nome" class="form-control text-leftt" type="text" placeholder="digite a Sigla" id="sigla">
                  </div>
                </div>
              </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="setPut">Gravar</button>
    </div>
  </div>
</div>
</template>