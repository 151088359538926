<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  //components: { Multiselect },
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      loader: { get: false },
      titleBody: "Incluir Unidade de Medidada",
      tabelas: {
        ttp_nome: null,
      },
    };
  },
  validations() {
    return {
      tabelas: {
        ttp_nome: { required },
      },
    };
  },

  mounted() {
    this.setNewTitle();
  },
  methods: {
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.$emit("doPost", this.tabelas);
      this.voltar();
    },
    voltar() {
      this.$emit("back");
    },
  },
};
</script>

<template>
  <div class="card">
    <b-modal
      v-model="showModal"
      id="modal-xl"
      size="xl"
      title="Tipo de Tabela de preços"
      hide-footer
    >
      <div class="form-row form-group" role="group">
        <div class="col-sm-12 col-md-3">
          <div class="form-group">
            <label for="tipo_sigla_id" class="col-form-label">Nome</label>
            <div class="col">
              <input
                v-model="tabelas.ttp_nome"
                class="form-control text-leftt"
                type="text"
                placeholder="digite a Sigla"
                id="sigla"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="col-md-12 mt-10">
          <button class="btn btn-light" @click="setPost">Gravar</button>
        </div>
      </div>
    </b-modal>
    <!-- <div class="card-body">
      <i
        v-if="loader.get"
        class="fa fa-spinner fa-5x fa-spin text-success text-center"
      ></i>
      <form id="form-tipo-movimento" role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-body>
            <h4>Tipo de Tabelas de Preço</h4>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                  <div class="col">
                    <input
                      v-model="tabelas.ttp_nome"
                      class="form-control text-leftt"
                      type="text"
                      placeholder="digite a Sigla"
                      id="sigla"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </form>
    </div> -->
  </div>
</template>